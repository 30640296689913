import { isWingTypeModifierGroup } from '../../../common/helpers/isWingTypeModifierGroup';

import { IFormatSelectedModifierName } from './types';

const formatSelectedModifierName: IFormatSelectedModifierName = (item) => {
    if (!item.name) return '';

    if (isWingTypeModifierGroup(item)) {
        return item.name;
    }

    const itemString = `${item.name}${item.selection ? ` (${item.selection})` : ''}`;

    if (item.name.toLowerCase().startsWith('no')) {
        return itemString;
    } else {
        return `Add ${itemString}`;
    }
};

export default formatSelectedModifierName;
