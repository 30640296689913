import { useMemo } from 'react';
import { getChangedModifiers } from '../helpers/getChangedModifiers';
import { useDomainMenuSelectors, useSelectedModifiers } from '../../redux/hooks/domainMenu';
import { IDefaultModifier, ISelectedModifier } from '../../redux/types';
import { TallyProductModel } from '../../@generated/webExpApi';

export interface IUseTallyModifiers {
    addedModifiers: ISelectedModifier[];
    removedDefaultModifiers: IDefaultModifier[];
    modifiersIsChanged: boolean;
    defaultModifiers: IDefaultModifier[];
    defaultSubModifiersData?: DefaultSubModifiersData[];
}

export interface DefaultSubModifiersData {
    parrentProduct: ISelectedModifier;
    defaultSubModifiers: IDefaultModifier[];
}

export const useTallyModifiers = (tallyItem: TallyProductModel): IUseTallyModifiers => {
    const { selectDefaultModifiers } = useDomainMenuSelectors();
    const defaultModifiers = selectDefaultModifiers(tallyItem.productId);
    const selectedModifiers = useSelectedModifiers(tallyItem);

    const selectedModifiersWithSubModifiers = selectedModifiers.filter(
        (selectedModifier) => selectedModifier.modifiers?.length > 0
    );

    const firstDefaultSubModifiers = selectDefaultModifiers(selectedModifiersWithSubModifiers[0]?.productId);

    const modifiers = useMemo(
        () => getChangedModifiers(selectedModifiers, defaultModifiers, firstDefaultSubModifiers),
        [selectedModifiers, defaultModifiers, firstDefaultSubModifiers]
    );

    const defaultSubModifiersData: DefaultSubModifiersData[] = selectedModifiersWithSubModifiers.reduce((acc, curr) => {
        const defaultSubModifiers = selectDefaultModifiers(curr.productId);
        if (defaultSubModifiers.length) {
            const defaultSubModifiersDataItem: DefaultSubModifiersData = {
                parrentProduct: curr,
                defaultSubModifiers: defaultSubModifiers,
            };

            return [...acc, defaultSubModifiersDataItem];
        }

        return acc;
    }, []);

    return { ...modifiers, defaultModifiers, defaultSubModifiersData };
};
