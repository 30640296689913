import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import Collapse from '@material-ui/core/Collapse';

import { TallyModifierModel } from '../../../@generated/webExpApi';

import { ISelectedModifier, IDefaultModifier } from '../../../redux/types';
import { formatPrice } from '../../../lib/domainProduct';
import { isWingTypeModifierGroup } from '../../../common/helpers/isWingTypeModifierGroup';
import getBrandInfo from '../../../lib/brandInfo';
import { DefaultSubModifiersData } from '../../../common/hooks/useTallyModifiers';
import { HOW_MUCH_TO_SHOW } from './constants';
import formatSelectedModifierName from './formatSelectedModifierName';
import formatRemovedModifierName from './formatRemovedModifierName';
import getShowMoreBtnLabel from './getShowMoreBtnLabel';
import styles from './index.module.css';
import { CONSTANTS } from '../../../constants/en/commons';
import formatUnavailableModifierName from './formatUnavailableModifierName';

interface IListModifiers {
    addedModifiers: ISelectedModifier[];
    removedModifiers: IDefaultModifier[];
    defaultModifiers?: IDefaultModifier[];
    defaultSubModifiersData?: DefaultSubModifiersData[];
    unavailableModifiers?: string[];
    unavailableSubModifiers?: string[];
    hidePrice?: boolean;
    isCollapsible?: boolean;
}

type ModifiersType = {
    name: string;
    productId: string;
    quantity?: number;
    price?: number;
    isWingType?: boolean;
} & TallyModifierModel;

interface IListMofidiersItem {
    name: string;
    productId: string;
    quantity?: number;
    price?: number;
    isWingType?: boolean;
    modifiers?: ModifiersType[];
    removedModifiers?: IDefaultModifier[];
    hidePrice?: boolean;
}

const { NOT_AVAILABLE } = CONSTANTS;

const wingTypeAtTopComparator = (a: ISelectedModifier, b: ISelectedModifier) => {
    return isWingTypeModifierGroup(a) ? -1 : isWingTypeModifierGroup(b) ? 1 : 0;
};

const displayQuantity = (quantity: number) => {
    const brand = getBrandInfo().brandId;
    switch (brand) {
        case 'Bww':
            return ` x${quantity}`;
        case 'Arbys':
            return ` (${quantity})`;
        default:
            return ` (${quantity})`;
    }
};

const ListSubModifiersItem = ({
    modifiers,
    hidePrice,
    removedModifiers = [],
}: {
    modifiers: IListMofidiersItem['modifiers'];
    hidePrice?: boolean;
    removedModifiers?: IListMofidiersItem['removedModifiers'];
}) => {
    return (
        <ul className={classnames(styles.list, styles.subList)}>
            {modifiers.map((subModifier) => (
                <li
                    key={subModifier.productId}
                    className={classnames('truncate-at-2', styles.modifierItem, {
                        [styles.wingTypeModifierItem]: subModifier.isWingType,
                    })}
                >
                    <div className={styles.modifierItemInfo}>
                        <span
                            className={classnames('truncate-at-2', 't-paragraph-hint', {
                                't-paragraph-hint-strong': subModifier.isWingType,
                            })}
                        >
                            {`${formatSelectedModifierName(subModifier)}` +
                                (subModifier.quantity > 1 ? displayQuantity(subModifier.quantity) : '')}
                        </span>

                        {!hidePrice && subModifier.price !== 0 && (
                            <span className="t-paragraph-hint">
                                {subModifier.price > 0 && '+'}
                                {formatPrice(subModifier.price * subModifier.quantity)}
                            </span>
                        )}
                    </div>
                </li>
            ))}
            {removedModifiers.map((removedModifier) => (
                <li key={removedModifier.productId} className={classnames('truncate-at-2', styles.modifierItem)}>
                    <span className={classnames('t-paragraph-hint')}>{formatRemovedModifierName(removedModifier)}</span>
                </li>
            ))}
        </ul>
    );
};

export const ListModifiersItem = (props: IListMofidiersItem) => (
    <li
        className={classnames('truncate-at-2', styles.modifierItem, {
            [styles.wingTypeModifierItem]: props.isWingType,
        })}
    >
        <div className={styles.modifierItemInfo}>
            <span
                className={classnames('truncate-at-2', 't-paragraph-hint', {
                    't-paragraph-hint-strong': props.isWingType,
                })}
            >
                {props.name + (props.quantity > 1 ? displayQuantity(props.quantity) : '')}
            </span>
            {!props.hidePrice && props.price ? (
                <span className="t-paragraph-hint">
                    {props.price > 0 && '+'}
                    {formatPrice(props.price)}
                </span>
            ) : (
                ''
            )}
        </div>
        {props.modifiers && (
            <ListSubModifiersItem
                modifiers={props.modifiers}
                removedModifiers={props.removedModifiers}
                hidePrice={props.hidePrice}
            />
        )}
    </li>
);

const UnavailableListWithSubModifiers = (props: IListMofidiersItem) => (
    <li className={classnames('truncate-at-2', styles.simpleModifierItem)}>
        <span className={'t-paragraph-hint'}>
            {props.name + (props.quantity > 1 ? displayQuantity(props.quantity) : '')}
        </span>
        {props.modifiers && <UnavailableListSubModifiers subModifiers={props.modifiers} />}
    </li>
);

export const UnavailableListSubModifiers = ({ subModifiers }: { subModifiers: IListMofidiersItem['modifiers'] }) => (
    <ul className={classnames(styles.list, styles.subList)}>
        {subModifiers.map((subModifier) => (
            <li key={subModifier.productId} className={classnames('truncate-at-2')}>
                <span className={classnames('t-paragraph-hint', styles.unavailableModifierItem)}>
                    {subModifier.name + (subModifier.quantity > 1 ? displayQuantity(subModifier.quantity) : '')}
                </span>
                <span className={styles.unavailableModifier}>{NOT_AVAILABLE}</span>
            </li>
        ))}
    </ul>
);

export const UnavailableListModifiersItem = (props: IListMofidiersItem) => (
    <li className={classnames('truncate-at-2', styles.unavailableListModifierItem)}>
        <span className={classnames('t-paragraph-hint', styles.unavailableModifierItem)}>
            {props.name + (props?.quantity > 1 ? displayQuantity(props.quantity) : '')}
        </span>
        <span className={styles.unavailableModifier}>{NOT_AVAILABLE}</span>
    </li>
);

export default function ListModifiers(props: IListModifiers): JSX.Element {
    const {
        removedModifiers,
        addedModifiers,
        unavailableModifiers = [],
        unavailableSubModifiers = [],
        defaultModifiers = [],
        defaultSubModifiersData = [],
        isCollapsible = true,
        hidePrice,
    } = props;

    const [isCollapsed, setIsCollapsed] = useState(isCollapsible);

    const listModifiers: IListMofidiersItem[] = useMemo(() => {
        if (unavailableModifiers.length || unavailableSubModifiers.length) {
            return [
                ...addedModifiers
                    .filter((item) => unavailableModifiers.includes(item.productId))
                    .map((item) => ({
                        name: formatUnavailableModifierName({ addedModifier: item }),
                        productId: item.productId,
                        quantity: item.quantity,
                        price: item.price * item.quantity,
                    })),
                ...defaultModifiers
                    .filter(
                        (defaultItem) =>
                            unavailableModifiers.includes(defaultItem.productId) &&
                            !removedModifiers.some((item) => item.productId === defaultItem.productId)
                    )
                    .map((defaultItem) => ({
                        name: formatUnavailableModifierName({ defaultModifier: defaultItem }),
                        productId: defaultItem.productId,
                    })),
                ...addedModifiers
                    .filter(
                        (item) =>
                            item.modifiers?.find((subModifier) =>
                                unavailableSubModifiers.includes(subModifier.productId)
                            ) && !unavailableModifiers.includes(item.productId)
                    )
                    .map((item) => ({
                        name: formatUnavailableModifierName({ addedModifier: item }),
                        productId: item.productId,
                        quantity: item.quantity,
                        price: item.price * item.quantity,
                        modifiers: item.modifiers.filter((modifier) =>
                            unavailableSubModifiers.includes(modifier.productId)
                        ),
                        removedModifiers: item.removedModifiers,
                    })),
                ...defaultSubModifiersData
                    .filter(
                        (item) =>
                            item.defaultSubModifiers?.find((subModifier) =>
                                unavailableSubModifiers.includes(subModifier.productId)
                            ) && !unavailableModifiers.includes(item.parrentProduct.productId)
                    )
                    .map((item) => ({
                        name: formatUnavailableModifierName({ defaultSubModifier: item }),
                        productId: item.parrentProduct.productId,
                        modifiers: item.parrentProduct.modifiers?.filter((modifier) =>
                            unavailableSubModifiers.includes(modifier.productId)
                        ),
                    })),
            ];
        }

        return [
            ...addedModifiers.sort(wingTypeAtTopComparator).map((item) => ({
                name: formatSelectedModifierName(item),
                productId: item.productId,
                quantity: item.quantity,
                price: item.price * item.quantity,
                isWingType: isWingTypeModifierGroup(item),
                modifiers: item.modifiers,
                removedModifiers: item.removedModifiers,
            })),
            ...removedModifiers
                .filter((removedModifier) => {
                    if (!removedModifier.relatedSelections) {
                        return true;
                    }

                    const notPresentInAdded = (relatedSizeId: string): boolean =>
                        !addedModifiers.find((added) => added.productId === relatedSizeId);

                    return removedModifier.relatedSelections.every(notPresentInAdded);
                })
                .map((item) => ({
                    name: formatRemovedModifierName(item),
                    productId: item.productId,
                    price: -1 * item.price,
                })),
        ];
    }, [
        removedModifiers,
        addedModifiers,
        unavailableModifiers,
        unavailableSubModifiers,
        defaultModifiers,
        defaultSubModifiersData,
    ]);

    const handleClick = (e) => {
        e.preventDefault();
        setIsCollapsed(false);
    };

    const renderModifiersList = (list: IListMofidiersItem) => {
        const key = `${list.productId}-${list.name}`;

        if (unavailableModifiers?.length || unavailableSubModifiers?.length) {
            if (!list?.modifiers) {
                return <UnavailableListModifiersItem key={key} {...list} />;
            }
            return <UnavailableListWithSubModifiers key={key} {...list} />;
        }

        return <ListModifiersItem key={key} {...list} hidePrice={hidePrice} />;
    };

    const otherItemsLength = listModifiers.length - HOW_MUCH_TO_SHOW;
    if (otherItemsLength > 0) {
        const visibleItems = listModifiers.slice(0, HOW_MUCH_TO_SHOW);
        const hiddenItems = listModifiers.slice(HOW_MUCH_TO_SHOW);
        return (
            <>
                <ul
                    className={classnames(styles.list, {
                        [styles.listWithItems]: visibleItems.length,
                    })}
                >
                    {visibleItems.map(renderModifiersList)}
                </ul>

                <Collapse in={!isCollapsed}>
                    <ul className={classnames(styles.list)}>{hiddenItems.map(renderModifiersList)}</ul>
                </Collapse>

                <a
                    href="#"
                    className={classnames(styles.button, {
                        [styles.collapsedButton]: !isCollapsed,
                    })}
                    onClick={handleClick}
                >
                    {getShowMoreBtnLabel(otherItemsLength)}
                </a>
            </>
        );
    }

    return (
        <ul
            className={classnames(styles.list, {
                [styles.listWithItems]: listModifiers.length,
            })}
        >
            {listModifiers.map(renderModifiersList)}
        </ul>
    );
}
