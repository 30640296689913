import { DefaultSubModifiersData } from '../../../common/hooks/useTallyModifiers';
import { IDefaultModifier, ISelectedModifier } from '../../../redux/types';

function formatUnavailableModifierName({
    addedModifier,
    defaultModifier,
    defaultSubModifier,
}: {
    addedModifier?: ISelectedModifier;
    defaultModifier?: IDefaultModifier;
    defaultSubModifier?: DefaultSubModifiersData;
}) {
    const addedOrDefaultModifier = addedModifier || defaultModifier;
    if (addedOrDefaultModifier) {
        return `${addedOrDefaultModifier.name}${
            addedOrDefaultModifier.selection ? ` (${addedOrDefaultModifier.selection})` : ''
        }`;
    }

    if (defaultSubModifier) {
        return `${defaultSubModifier.parrentProduct.name}${
            defaultSubModifier.parrentProduct.selection ? ` (${defaultSubModifier.parrentProduct.selection})` : ''
        }`;
    }
}

export default formatUnavailableModifierName;
